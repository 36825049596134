import React, { useState, useContext, useEffect, useRef } from "react";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import ModalStandard from "../GeneralComponents/ModalStandard";
import { useNavigate } from "react-router-dom";
import InputFile from "../GeneralComponents/InputFile";


export default function Apelacion() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [modalError, setModalError] = useState(false);
    const [modalContent, setModalContent] = useState("");


    const rutRef = useRef(null);    
    const [evidence, setEvidence] = useState(null);

  const apelacionRef = useRef();

  const [formData, setFormData] = useState({
    operator_id: "",
    operator_name: "",
    zona: "",
    periodo: "",
    description: "",
    evidence: "",
  });



    const setFile = (archivo) => {
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
  };

  

  function getOperator() {
      axios.getOperator(userSesion.rut)
          .then((response) => {
              setFormData({ ...formData, operator_name: response.data.operador, operator_id: response.data.id_operador+"" })
          })
          .catch((error) => {
          })
    }

  const save = async (event) => {
    event.preventDefault();
    
    handleDownloadPdf();

  };

  const handleDownloadPdf = () => {
    const element = apelacionRef.current;

    // Renderiza el elemento en un canvas y maneja la promesa con .then() y .catch()
    html2canvas(element, { scale: 2 })
        .then((canvas) => {

            setLoading(true);
            //const imageData = canvas.toDataURL('image/png');
            const imageData = canvas.toDataURL('image/jpeg', 1);
           
            const pdfWidth = 210;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 60]);

     
            pdf.setFillColor(255, 255, 255);

            pdf.rect(0, 0, pdfWidth, pdfHeight + 140, 'F');
            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            
            const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                evidence: evidence && evidence.archivo ? [evidence.archivo] : [],
            }

            form.append("data", JSON.stringify(localFormData));
            
            for (let i = 0; i < localFormData["evidence"].length; i++) {
              console.log(localFormData["evidence"][i]);
                form.append("evidence", localFormData["evidence"][i]);
            }
            
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesContingencias(form)
                .then((response) => {
                    setLoading(false);
                    //setSubmitConfirm(!submitConfirm)
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error generando el PDF:', error);
            throw error;
        });
};




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

      useEffect(() => {
        getOperator()
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);

  return (
    <PageBody>
      <PageBgBlock pad="0" >
      <Box ref={apelacionRef} sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN PRE-FACTURA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su factura proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, como documentos, correos electrónicos o comprobantes, para que su caso pueda ser evaluado de manera justa y objetiva.
          </Typography>
        </Box>

        <form onSubmit={save} id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_id"
                    label="ID Operador"
                    size="small"
                    value={formData.operator_id}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </Grid>



            <Grid container spacing={2}>

              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Periodo"
                        size="small"
                        value={formData.periodo}
                        sx={{ width: "100%" }}
                    />
                </FormControl>

                            </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="zona-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    labelId="zona-label"
                    id="zona"
                    value={formData.zona}
                    label="Zona"
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        zona: event.target.value,
                      })
                    }
                  >


                    <MenuItem value={"Critica"}>1</MenuItem>
                    <MenuItem value={"Mayor"}>2</MenuItem>
                    <MenuItem value={"Menor"}>...</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

                            

            </Grid>

          
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el EES"
                size="small"
                value={formData.description}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    description: event.target.value,
                  })
                }
              />
            </FormControl>

            <InputFile onFileSelect={setFile} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
          </Stack>

        </form>
          </Box>
          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
          <PageButtonBlack form="contingencia" loading={loading}> Firmar y enviar</PageButtonBlack>
          </Stack>
      </PageBgBlock>
      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/prefactura_lista")} onClose={handleClose}></ModalStandard>
    </PageBody>
  );
}
