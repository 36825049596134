import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Grid, Button, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CircularProgress } from '@mui/material';
import ListStepper from "../GeneralComponents/ListStepper";
import ListEmpty from "../GeneralComponents/ListEmpty";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, REJECTED, APROVED, ROL_ADMIN, ROL_OP_ADMIN, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_CON_MINEDUC, ROL_VAL_MINEDUC} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  { id: "periodo", label: "Periodo", width: "12%", minWidth: "120px" },
  { id: "rut_emisor", label: "Rut Emisor", width: "12%", minWidth: "120px" },
  {
    id: "estado_resumen",
    label: "Estado",
    width: "16%",
    minWidth: "160px",
    align: "left",
  },
  {
    id: "firma",
    label: "Firma",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "detalle",
    label: "Detalle Documento",
    width: "12%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "url",
    label: "Descarga PDF",
    width: "10%",
    minWidth: "100px",
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "operador",
    label: "Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];




export default function ContingenciasList() {
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loadingTable, setLoadingTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const navigate = useNavigate();

  const canSign = (estado) => {
    if (
      (estado === NO_EES || estado === NO_EES_TEMP) &&
      includeRol([ROL_REP_EES, ROL_REP_EES_TEMP])
    ) {
      return true;
    } else if (
      estado === NO_SUBTEL &&
      includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL])
    ) {
      return true;
    } else if (
      estado === NO_MINEDUC &&
      includeRol([ROL_VAL_MINEDUC, ROL_CON_MINEDUC])
    ) {
      return true;
    }

    return false;
  };


    const downloadPDF = (url) => {
      setLoadingPDF(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = blobUrl;
                a.download = 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {setLoadingPDF(false); console.error('Error al descargar el archivo:', error); window.open(url, '_blank'); });
    };

    const downloadZip = async (urls) => {
        const zip = new JSZip();

        // Agregar cada archivo PDF al zip
        const promises = urls.map(async (url) => {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Error al descargar el archivo');
            const blob = await response.blob();
            const filename = url.split('/').pop();
            zip.file(filename, blob);
        });

        try {
            await Promise.all(promises); 
            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, 'archivos.zip'); 
        } catch (error) {
            console.error('Error al crear el ZIP:', error);
        }
    };


  const fetchData = async () => {
    setLoadingTable(true);
    axios
      .getContingenciasData()
      .then((response) => {
        const modifiedData = response.data.map((row) => {
          return {
            ...row,
            periodo: row.periodo.split("T")[0],
            estado_resumen:
              row.estado !== "Aprobado" && row.estado !== REJECTED
                ? "En Proceso"
                : row.estado,
              puede_firmar: canSign(row.estado),
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

    if (
      includeRol([ROL_OP_ADMIN, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_VAL_SUBTEL, ROL_ADMIN ])
    ) {
      const columnOrder = [
            "operador",
            "rut_emisor",
            "estado_resumen",
            "firma",
            "detalle",
            "url",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
    } else if (includeRol([ROL_ADMIN_SUBTEL])) {
       const columnOrder = [
            "operador",
            "periodo",
            "estado_resumen",
            "firma",
            "detalle",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };


  useEffect(() => {
    setPageTitle("Listado de contingencias");
  }, [pageTitle, setPageTitle]);

  function Row(props) {
    const { row, index, column } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {}, []);


  function transformData(data) {

          return {
            ...data,
            progress: {
            state: data.estado,
              steps: data.firmas.map(firma => ({
                  type: firma.estado_firma,
                  institution: {
                      type: ["MINEDUC", "SUBTEL", "EES"].find(role => firma.rol_firma.includes(role)) || "FUS",
                      name: ["MINEDUC", "SUBTEL", "EES"].find(role => firma.rol_firma.includes(role)) || "FUS"
                  },
                  in_charge: [firma.nombre]  // Puedes personalizar esta lista según las personas involucradas
              }))
          }
        }
  }

    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)} 
          hover
          role="checkbox"
          tabIndex={-1}
          key={"TR" + index}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          {filteredColumns.map((column, index) => {
            const value = row[column.id];
            return (
              <TableCell key={"TC" + index} align={column.align}>
                {column.id === "url" ? (
                  <Button
                  disabled={loadingPDF}
                    onClick={(e) => { e.stopPropagation();
                      downloadPDF(value)
                    }}
                    key={`${column.id}.button.${index}`}
                    variant="text"
                    startIcon={
                      <PictureAsPdfIcon
                        key={`${column.id}.icon.${index}`}
                        sx={{ color: "#111" }}
                      />
                    }
                  >
                    <Stack>
                      <Typography
                        key={`${column.id}.typ.${index}`}
                        sx={{
                          color: "#111",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                        }}
                      >
                        Descargar
                      </Typography>
                    </Stack>
                  </Button>
                ) : column.id==="detalle" ? (
                  <Button
                    onClick={(e) => { 
                      e.stopPropagation();
                      navigate('/contingencias_detalle/'+row["id"]);
                    }}
                    key={`${column.id}.button.${index}`}
                    variant="text"
                  >
                    <Typography
                      key={`${column.id}.typ.${index}`}
                      sx={{
                        color: "#111",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                    >
                      Ver
                    </Typography>
                  </Button>
                ) : column.id.startsWith("firma") && row["estado_resumen"] !== REJECTED ? (
                  <PageButtonBlack
                    width="10rem"
                    loading={!row["puede_firmar"]}
                    pad="0 1rem"
                    onClick={(e) => { 
                      e.stopPropagation();
                      navigate("/contingencias_firma/"+row["id"]);
                    }}
                  >
                    {row["puede_firmar"] ? "Firmar" : "Firmado"}
                  </PageButtonBlack>
                ) :

                column.id === 'estado_resumen' ? (
                
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'no-wrap',
                    }}>
                      <FiberManualRecordIcon sx={{ fontSize: "0.75rem", color: value === 'En Proceso' ? '#FFE263' : value === 'Aprobado' ? '#41AE43' : '#FB3B3B' , marginRight: "0.5rem" }} />

                      {value}
                      <Box sx={{ flexGrow: 1 }} />
                      {value !== 'En Proceso' ? null : open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </div>

                )
                :
                 (
                  value
                )}

                {row["puede_firmar"]}
              </TableCell>
            );
          })}
        </TableRow>
        {row["estado_resumen"] === "En Proceso" ? (
          <TableRow key={row.code + 1}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <ListStepper
                    row={transformData(row)}
                  ></ListStepper>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  return (
    <PageBody>
      <Box width="100%">
        {includeRol([
          ROL_OP_ADMIN,
          ROL_REP_EES,
          ROL_REP_EES_TEMP,
          ROL_VAL_SUBTEL,
          ROL_ADMIN,
        ]) ? (
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "-2rem" }}
          >
            <Grid item xs={3}>
              <InputFilter
                data={rows}
                item={"periodo"}
                name={"Periodo"}
                callback={filterRecords}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilter
                data={rows}
                item={"estado"}
                name={"Estado"}
                callback={filterRecords}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilter
                data={rows}
                item={"rut_emisor"}
                name={"Emisor"}
                callback={filterRecords}
              />
            </Grid>
            {includeRol([
              ROL_REP_EES,
              ROL_REP_EES_TEMP,
              ROL_VAL_SUBTEL,
              ROL_ADMIN,
            ]) ? (
              <Grid item xs={3}>
                <InputFilter
                  data={rows}
                  item={"operador"}
                  name={"Operador"}
                  callback={filterRecords}
                />
              </Grid>
            ) : null}
          </Grid>
        ) : includeRol([ROL_ADMIN_SUBTEL]) ? (
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "28px", marginTop: "17px" }}
          >
            <Grid item xs={3}>
              <InputFilter
                data={rows}
                item={"operador"}
                name={"Operador"}
                callback={filterRecords}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFilter
                data={rows}
                item={"periodo"}
                name={"Periodo"}
                callback={filterRecords}
              />
            </Grid>
          </Grid>
        ) : null}

        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            background: "#FFFFFF",
            borderRadius: "0.25rem",
            marginBottom: "0.75rem",
          }}
        >
          <Typography
            variant="h1"
            color="inherit"
            noWrap
            sx={{ textAlign: "center", padding: "0.5rem" }}
          >
            LISTADO CONTINGENCIAS
          </Typography>
        </Paper>


        {loadingTable ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :
        <PageBgBlock pad="0">
          <Paper
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <TableContainer>
              <Table size="small" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {filteredColumns.map((column, index) => (
                      <TableCell
                        key={"CL" + index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row key={index} row={row} index={index} />
                    ))}
                </TableBody>
              </Table>
                    {tableData.length == 0 ? (<ListEmpty></ListEmpty>) : null}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </PageBgBlock>

          }
       
      </Box>
    </PageBody>
  );
}
