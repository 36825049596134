import {Typography, Box} from "@mui/material";


const PageCoverImg = (props) => {


    const mb = props.mb ? props.mb : "0";
   return   <Box sx={{display: 'grid', gridTemplateRows: '1fr', height:'100%', borderRadius: '20px', overflow: 'hidden'}}>

    <Box  alt="Logo sagec" sx={{  backgroundPosition: 'top', backgroundImage: props.img, height:'100%' , backgroundSize: 'cover',  width: '100%', marginBottom: mb}}> </Box>
        </Box>
}

export default PageCoverImg;



        