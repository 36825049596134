import {Box, Paper} from "@mui/material";

export default function PageBgColor(props) {
	const pad = props.pad ? props.pad : "22px 57px 32px";
	const bgcolor = props.bgcolor ? props.bgcolor : "#FFFFFF";
	const height = props.height ? props.height : "auto";

    return (
		<Box sx={{backgroundColor: bgcolor, padding: pad, borderRadius: '20px', height: height}}>
			<Box sx={{ margin: 'auto'}}>
				{props.children}
			</Box>
		</Box>
    );
}








