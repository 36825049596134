import { Box, Button } from "@mui/material";
import { LoadingButton } from '@mui/lab';

export default function PageButtonBlack(props) {

    const fs = props.fs ? props.fs : "14px";
    const pad = props.pad ? props.pad : "8px 16px";
    const width = props.width ? props.width : '300px';
    const icon = props.icon ? props.icon : '';

    const bgColor = props.loading ? '#FFF' : '#003B8D';
    const border = props.loading ? "2px solid rgba(0, 59, 141, 0.30)" : '2px solid #003B8D';
    const color = props.loading ? "rgba(0, 59, 141, 0.30)" : '#FFF';
    return (
        <Box sx={{width: width, display: 'block', margin:'auto'}}>
        {props.onClick ? 
        <Button  onClick={props.onClick} startIcon={icon} disabled={props.loading} sx={{border: border,textTransform: 'none', minWidth: width, width: width , backgroundColor: bgColor, borderRadius: '4px', color: color, fontSize: fs, padding: pad, lineHeight: '28px', fontWeight: '500', margin: 'auto'}}>
            { props.children}
        </Button> 
        :
        <LoadingButton type="submit"  form={props.form} startIcon={icon} loading={props.loading}  sx={{border: border, textTransform: 'none', opacity: props.loading ? 0.7 : 1, minWidth: width, width: width, backgroundColor: bgColor, borderRadius: '4px', color: color, fontSize: fs, padding: pad, lineHeight: '28px', fontWeight: '500', margin: 'auto'}}>
            {props.children}
        </LoadingButton>}
        </Box>

       

    );
}











