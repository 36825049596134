import {Box, Modal, Typography, Paper, IconButton} from "@mui/material";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import CloseIcon from "@mui/icons-material/Close";
import PageButtonBlue from "./PageButtonBlue";

export default function ModalStandard(props) {

    return (
		<Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        slotProps={{ backdrop: { sx: { opacity: "0.1!important" } } }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "749px",
            height: "261px",
            backgroundSize: "background.paper",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>

            <Box>
              <Box>
				  {props.error ? (
				    <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
				  ) : (
				    <DoneOutlineOutlinedIcon color="success" sx={{ fontSize: 45 }} />
				  )}
				</Box>
            </Box>
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
              sx={{
                fontSize: "26px",
                lineHeight: "66px",
                margin: "0",
                color: "#333",
              }}
            >
              {props.title}
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                {props.msg}
              </Typography>
            </Box>

            <PageButtonBlue onClick={props.onClick}>
              {props.btnText}
            </PageButtonBlue>
          </Paper>
        </Box>
      </Modal>
    );
}











      