import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import GeneralContext from '../Components/GeneralContext/GeneralContext';


const ProtectedRoute = ({ children, requiredResource }) => {
  const { userResources, loading, isAuthenticated } = useContext(GeneralContext);

  if (!userResources.includes(requiredResource)) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default ProtectedRoute;