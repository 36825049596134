import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StepperProceso from './StepperProceso';
import GeneralContext from '../GeneralContext/GeneralContext';


function createData(id, fas_id, ees, state, date, description) {
    return { id, fas_id, ees, state, date, description };
}
const rows = [
    createData("0", "201", "EES 1", "Pendiente de Validación", "2024-06-01", "Alta de Servicio"),
    createData("2", "202", "EES 2", "Pendiente de Validación", "2024-06-02", "Alta de Servicio"),
    createData("3", "203", "EES 3", "Validado", "2024-06-03", "Alta de Servicio"),
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const buttons = [
    <IconButton
        color="success"
    >
        <CheckCircleOutlineIcon />
    </IconButton>,
    <IconButton
        color="error"
    >
        <DoDisturbIcon />
    </IconButton>,
];


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function Validador() {
    const { pageTitle, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
    const [open, setOpen] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setPageTitle("Interfaz del Validador SUBTEL - SAGEC")
        setDrawerHidding(false)
        setPageLoaded(true)
    }, [pageTitle, setPageTitle, setDrawerHidding]);


    if (pageLoaded) {
        return (
            <Box component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    //overflow: "auto",
                }}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Validación de FAS
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: "75%" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="center">FAS ID</TableCell>
                                        <TableCell align="center">EES</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Fecha</TableCell>
                                        <TableCell align="center">Descripción</TableCell>
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="center">{row.fas_id}</TableCell>
                                            <TableCell align="center">{row.ees}</TableCell>
                                            <TableCell align="center">{row.state}</TableCell>
                                            <TableCell align="center">{row.date}</TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                            <TableCell align="center">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        '& > *': {
                                                            m: 1,
                                                        },
                                                    }}
                                                >
                                                    <Button variant="primary" onClick={handleOpen}>Proceso de Validación</Button>
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                            <Card sx={{ maxWidth: "100%" }}>
                                                                <CardContent>
                                                                    <StepperProceso />
                                                                </CardContent>
                                                            </Card>
                                                        </Box>
                                                    </Modal>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FormControl>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Validación de FUS
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: "75%" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="center">FAS ID</TableCell>
                                        <TableCell align="center">EES</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Fecha</TableCell>
                                        <TableCell align="center">Descripción</TableCell>
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="center">{row.fas_id}</TableCell>
                                            <TableCell align="center">{row.ees}</TableCell>
                                            <TableCell align="center">{row.state}</TableCell>
                                            <TableCell align="center">{row.date}</TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                            <TableCell align="center">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        '& > *': {
                                                            m: 1,
                                                        },
                                                    }}
                                                >
                                                    <ButtonGroup size="large" aria-label="Large button group">
                                                        {buttons}
                                                    </ButtonGroup>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FormControl>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Validación de Pre-facturas
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: "75%" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="center">FAS ID</TableCell>
                                        <TableCell align="center">EES</TableCell>
                                        <TableCell align="center">Estado</TableCell>
                                        <TableCell align="center">Fecha</TableCell>
                                        <TableCell align="center">Descripción</TableCell>
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="center">{row.fas_id}</TableCell>
                                            <TableCell align="center">{row.ees}</TableCell>
                                            <TableCell align="center">{row.state}</TableCell>
                                            <TableCell align="center">{row.date}</TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                            <TableCell align="center">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        '& > *': {
                                                            m: 1,
                                                        },
                                                    }}
                                                >
                                                    <ButtonGroup size="large" aria-label="Large button group">
                                                        {buttons}
                                                    </ButtonGroup>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FormControl>
                </Stack>
            </Box>
        );
    } else {
        return(
        <Box></Box>
        );
    }
}
