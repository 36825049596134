import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputRut from "../GeneralComponents/InputRut";
import {  Button, InputAdornment, } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";



import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_VAL_MINEDUC} from "../../Constants/Constants";

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasPreview() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);


  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: userSesion.nombre,
    rut: userSesion.rut,
    ees_name: "",
    operator_id: "",
    id_zona: "",
    id_ticket: "",
    operator_name: "",
    fecha_inicio: dayjs(),
    fecha_solucion: dayjs(),
    fecha_recursos: dayjs(),
    fecha_mda: dayjs(),
    tipo_evento: "",
    origen: "",
    extension: "",
    severidad: "",
    rbd: "",
    jornada: "",
    descripcion_ees: "",
    descripcion_op: "",
    evidence: "",
  });
    const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado Contingencias', path: '/contingencias_lista' },
    { name: 'Firma Contingencia', path: '/contingencias_firma/'+id },
  ];

    const fetchData = async (query) => {
    setLoading(true);
    axios
      .getContingencia(id)
      .then((response) => {
        setFormData({...response.data.datos, fecha_inicio: dayjs(response.data.datos['fecha_inicio']), fecha_solucion: dayjs(response.data.datos['fecha_solucion'])})
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData("");
  }, []);

      useEffect(() => {
        fetchData()
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);

  return (
    <PageBody>
      <PageBgBlock pad="0" >
          <BreadcrumbStandard links={links} />
      {loading ?
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                width: '100%',
                backgroundColor: 'white'
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
            :

              <Box sx={{ padding: "2rem 9rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          LEVANTAMIENTO DE CONTINGENCIA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  Complete el siguiente formulario para registrar una contingencia (Ticket Mesa de Ayuda).  Asegúrese de proporcionar todos los datos requeridos para facilitar el proceso de asistencia y resolución. Nuestro equipo revisará su solicitud y le brindará el soporte necesario a la brevedad.
          </Typography>
        </Box>

        <form  id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL EMISOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="rut"
                    label="Rut Operador Administrador"
                    size="small"
                    value={formData.rut}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre"
                    label="Nombre y Apellido"
                    size="small"
                    value={formData.name}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_id"
                    label="ID Operador"
                    size="small"
                    value={formData.operator_id}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>

                        <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS EES
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
              <Box >
                <FormControl fullWidth variant="outlined">
                                <TextField
                                    required
                                    disabled
                                    label="RBD"
                                    size="small"
                                    value={formData.rbd}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                    </Box>
              </Grid>
              <Grid size={6}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    required
                                    disabled
                                    label="ID Ticket mesa de ayuda EES"
                                    size="small"
                                    value={formData.id_ticket}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>
             
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                                <TextField

                                    label="ID Zona"
                                    size="small"
                                    disabled
                                    value={formData.id_zona}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>

                             <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="jornada"
                    label="Tipo de jornada"
                    size="small"
                    value={formData.jornada}
                  />
                </FormControl>
              </Grid>
                            
                            <Grid size={12} >
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.ees_name}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                            </Grid>
            </Grid>

            <Box>
            <InputRut checked={formData.checked} rut={formData.ees_temporal_manager}></InputRut>
            </Box>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DATOS DE FALLA
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                  disabled
                    sx={{ width: "100%" }}
                    label="Fecha y hora reporte a MDA"
                    value={formData.fecha_mda}
                    disableFuture
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                  disabled
                    sx={{ width: "100%" }}
                    label="Fecha y hora ocurrencia"
                    value={formData.fecha_inicio}
                    disableFuture
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora recursos"
                    value={formData.fecha_recursos}
                    disabled
                    disableFuture
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora solución"
                    value={formData.fecha_solucion}
                    disabled
                    disableFuture
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="severidad-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="severidad-label"
                    id="severidad"
                    value={formData.severidad}
                    label="Severidad"
                    size="small"
                    sx={{ width: "100%" }}
                  >


                    <MenuItem value={"Critica"}>Critica</MenuItem>
                    <MenuItem value={"Mayor"}>Mayor</MenuItem>
                    <MenuItem value={"Menor"}>Menor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="tipo_evento-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Código tipo de evento
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="tipo_evento-label"
                    id="tipo_evento"
                    value={formData.tipo_evento}
                    label="Código tipo de evento"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"1"}>1 - Falla física equipo de datos cliente</MenuItem>
                    <MenuItem value={"2"}>2 - Bloqueo equipo de datos cliente</MenuItem>
                    <MenuItem value={"3"}>3 - Corte enlace físico (Fibra óptica o cobre)</MenuItem>
                    <MenuItem value={"4"}>4 - Corte enlace microondas</MenuItem>
                    <MenuItem value={"5"}>5 - Caída plataforma satelital</MenuItem>
                    <MenuItem value={"6"}>6 - Caída enlace internacional</MenuItem>
                    <MenuItem value={"7"}>7 - Falla nodo de red</MenuItem>
                    <MenuItem value={"8"}>8 - Falla red de transporte</MenuItem>
                    <MenuItem value={"9"}>9 - Error de configuración firewall</MenuItem>
                    <MenuItem value={"10"}>10 - Error de configuración DNS</MenuItem>
                    <MenuItem value={"11"}>11 - Error de configuración otro nodo de servicio</MenuItem>
                    <MenuItem value={"12"}>12 - Falla de equipo firewall</MenuItem>
                    <MenuItem value={"13"}>13 - Falla de equipo DNS</MenuItem>
                    <MenuItem value={"14"}>14 - Falla de equipo otro nodo de servicio</MenuItem>
                    <MenuItem value={"15"}>15 - Corte de energía EES</MenuItem>
                    <MenuItem value={"16"}>16 - Corte suministro público energía</MenuItem>
                    <MenuItem value={"17"}>17 - Otro responsabilidad del operador</MenuItem>
                    <MenuItem value={"18"}>18 - Otro responsabilidad de terceros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="origen-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Origen de la falla
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="origen-label"
                    id="origen"
                    value={formData.origen}
                    label="Origen de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Falla interna al adjudicatario"}>Falla interna al adjudicatario</MenuItem>
                    <MenuItem value={"Falla externa al adjudicatario"}>
                      Falla externa al adjudicatario
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="extension-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Extensión de la falla
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="extension-label"
                    id="extension"
                    value={formData.extension}
                    label="Extensión de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                    <MenuItem value={"Evento mayor"}>
                      Evento mayor
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA FALLA
            </Typography>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                disabled
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el EES"
                size="small"
                value={formData.descripcion_ees}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                disabled
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el Operador"
                size="small"
                value={formData.descripcion_op}
              />
            </FormControl>

            <Grid container spacing={2}>
            <Grid size={9}>
              <TextField
                disabled
                size="small"
                variant="outlined"
                placeholder="Subir evidencia (formato PDF)"
                value={formData.evidence}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{marginLeft: "10px"}}>
                      <ImageSearchIcon sx={{color: 'black'}}/>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                sx={{ width: "100%", maxWidth: "100%" }}
                readOnly
              />
            </Grid>
            <Grid size={3}>
              <Button fullWidth variant="text" color="primary" disabled startIcon={<FileUploadIcon />} >
                Añadir archivo
              </Button>
            </Grid>
          </Grid>
          </Stack>

        </form>
          </Box>

        }
      </PageBgBlock>
    </PageBody>
  );
}
