import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from '@mui/lab';
import OutboxIcon from '@mui/icons-material/Outbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GeneralContext from '../GeneralContext/GeneralContext';
import Grid from '@mui/material/Grid2';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { NumericFormat } from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from "../../api/axios";
import Alert from '@mui/material/Alert';
import { Divider } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import InputRut from "../GeneralComponents/InputRut";

import ModalStandard from "../GeneralComponents/ModalStandard";


import { useNavigate } from "react-router-dom";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function FusForm() {

    const { pageTitle, setPageTitle, setDrawerHidding, authJWT, userSesion } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [checked, setChecked] = React.useState(false);
    const [images, setImages] = React.useState({});
    const [techTypes, setTechTypes] = useState([]);
    const [RBDList, setRBDList] = useState([""]);
    const [submitConfirm, setSubmitConfirm] = useState(false);
    const [rbdLoading, setRbdLoading] = React.useState(false);
    const [autoCompleteInput, setAutoCompleteInput] = React.useState("");
    const fusRef = useRef();
    const autoCompleteRef = useRef(null);
    const rutRef = useRef(null);
    const [error, setError] = useState("");
    const [errorRut, setErrorRut] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [modalError, setModalError] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const [formData, setFormData] = useState(
        {
            "rut": userSesion.id,
            "operator_name": "",
            "rbd": "",
            "ees_name": "",
            "ees_managers": ["", ""],
            "ees_temporal_manager_list": [""],
            "ees_temporal_manager": "",
            "tech_id": "",
            "int_speed_down": "",
            "installation_date": dayjs(),
            "speed_up": "",
            "speed_down": "",
            "int_speed_down": "",
            "latency": "",
            "packet_loss": "",
            "checked": false,
            "images": [],
            "fas_id": ""
        }
    )

    const handleErrorChange = (error) => {
        setErrorRut(error);
    };

const handleDownloadPdf = () => {
    const element = fusRef.current;

    // Renderiza el elemento en un canvas y maneja la promesa con .then() y .catch()
    html2canvas(element, { scale: 2 })
        .then((canvas) => {
            //const imageData = canvas.toDataURL('image/png');
            const imageData = canvas.toDataURL('image/jpeg', 1);
           
            const pdfWidth = 210;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 140]);

     
            pdf.setFillColor(255, 255, 255);
            pdf.rect(0, 0, pdfWidth, pdfHeight + 140, 'F');

            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

            const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                installation_date: formData["installation_date"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
                rbd: autoCompleteInput ? autoCompleteInput : "" ,
            }

            form.append("data", JSON.stringify(localFormData));
            for (let i = 0; i < localFormData["images"].length; i++) {
                form.append("evidence", localFormData["images"][i]);
            }
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesFus(form)
                .then((response) => {
                    setLoading(false);
                    setSubmitConfirm(!submitConfirm);
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        })
        .catch((error) => {
            console.error('Error generando el PDF:', error);
            throw error;
        });
};

    function getFile() {

        handleDownloadPdf()
 

    }

    function getEes() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            axios.getEesData(rbd_temp, "1")
                .then((response) => {
                    let tempRep = []
                    if (response.data.nombres_representantes.length === 1) {
                        tempRep[0] = response.data.nombres_representantes[0]
                        tempRep[1] = "Sin representante"
                    }
                    else {
                        tempRep = response.data.nombres_representantes
                    }
                    setFormData({
                        ...formData,
                        ["fas_id"]: response.data.fas_id.length > 0 ? response.data.fas_id[0] : "",
                        ["ees_name"]: response.data.nombre_colegio,
                        ["ees_managers"]: tempRep.length > 0 ? tempRep : ["Sin representante", "Sin representante"],
                        ["ees_temporal_manager_list"]: response.data.nombres_representantes_temporales.length > 0 ? response.data.nombres_representantes_temporales : ["Sin representante"],
                    })
                    setError("");
                })
                .catch((error) => {
                    setFormData({
                        ...formData,
                        ["fas_id"] : "",
                        ["ees_name"]: "Colegio no encontrado",
                        ["ees_managers"]: ["Colegio no encontrado", "Colegio no encontrado"],
                        ["ees_temporal_manager_list"]: [""],
                    })
                    setError("Colegio no encontrado");
                })
        }
    }

    function getTechType() {
        axios.getTechTypes()
            .then((response) => {
                setTechTypes(response.data)
            })
            .catch((error) => {
            })
    }
    function getRBD() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            setRbdLoading(true)
            axios.getRBD(rbd_temp)
                .then((response) => {
                    setRBDList(response.data.rbd_list)
                    setRbdLoading(false)
                })
                .catch((error) => {
                    setRbdLoading(false)
                })
        }
    }
    function getOperator() {
        axios.getOperator(userSesion.id)
            .then((response) => {
                setFormData({ ...formData, ["operator_name"]: response.data.operador })
            })
            .catch((error) => {
            })
    }

    function handleRBD() {
        getEes()
    }

    function handleClick(event) {

        event.preventDefault();

        if (error) {
            autoCompleteRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            return; 
        }
        if (errorRut){
            rutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            return
        }
        if (submitConfirm) {
            setLoading(true);
            try {
                getFile()

            } catch (error) {
                console.log("Error ", error)
                setLoading(false);
                setSubmitConfirm(!submitConfirm)
            }
        }
        else {
            setSubmitConfirm(!submitConfirm)
        }

    }
    const handleSwitch = (event) => {
        setFormData({ ...formData, ["checked"]: event.target.checked })
    };
    function handleDropDown(value) {
        setFormData({ ...formData, ["tech_id"]: value })
    }

    useEffect(() => {
        setPageTitle("Formulario electrónico de Upgrade de Servicio (FUS)")
        getTechType()
        getOperator()
        setDrawerHidding(false)
        setPageLoaded(true)
    }, []);

    useEffect(() => {
        setFormData({ ...formData, ["rbd"]: autoCompleteInput ? autoCompleteInput : "" })
        getRBD()
        getEes()
    }, [autoCompleteInput]);

    if (pageLoaded) {
        return (
            <>
            <Box  sx={{ background: "#F6F6F6", paddingY: "2.5rem", paddingX: "4.5rem" }}>
                <Stack sx={{ background: "#FFFFFF", borderRadius: "0.5rem" }} >
                <Box ref={fusRef} sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}>
                <Stack  alignItems="left" justifyContent="center" spacing={2}>
                    <Typography
                        variant="h1"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                    >
                        FORMULARIO FUS
                    </Typography>

                    <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
                    <Typography
                        variant="small"
                        color="inherit"
                        sx={{ textAlign: "center", justifyContent: "center", paddingY: "0", marginTop: '0'}}
                    >
                         N° folio:    <Typography variant="small" sx={{color: '#FFF', paddingLeft:'1rem', marginTop: '0'}}>00000000</Typography>
                    </Typography>
                    </Box>
                    <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
                    <Typography
                        variant="small"
                        color="inherit"
                        sx={{ textAlign: "center", justifyContent: "center", paddingY: "0" }}
                    >
                         Vinculado a FAS N° folio: {String(formData.fas_id || '').padStart(8, '0')} 
                    </Typography>
                    </Box>
                    <Typography
                        variant="body"
                        color="inherit"
                        sx={{ textAlign: "center", justifyContent: "center", paddingX: "8rem" }}
                    >
                        Le presentamos el Formulario de Upgrade de Servicio, por favor llene todos los campos indicados para un proceso exitoso entre todos los involucrados (operador, EES, SUBTEL y MINEDUC)
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        DATOS DEL OPERADOR
                    </Typography>
                    <form onSubmit={handleClick} id="fus">
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>

                        <Grid container spacing={2}>
                            <Grid size={6} >
                                <TextField
                                    disabled
                                    id="outlined-required"
                                    label="RUT Operador Instalador"
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={formData.rut}
                                    onChange={(newValue) => setFormData({ ...formData, ["rut"]: newValue.target.value })}
                                />
                            </Grid>
                            <Grid size={6}>

                                <TextField
                                    id="outlined-required"
                                    label="Operador"
                                    disabled
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={formData.operator_name}
                                    onChange={(newValue) => setFormData({ ...formData, ["operator_name"]: newValue.target.value })}
                                />

                            </Grid>
                        </Grid>
                    </FormControl>

                    <Typography
                        component="h1"
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Datos del EES
                    </Typography>

                    <Box ref={autoCompleteRef}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <Autocomplete
                            key="rbd"
                            value={autoCompleteInput}
                            onChange={(event, newValue) => setAutoCompleteInput(newValue ? newValue : "")}
                            inputValue={autoCompleteInput}
                            onInputChange={(event, newValue) => setAutoCompleteInput(newValue ? newValue : "")}
                            disablePortal
                            loading={rbdLoading}
                            loadingText="Cargando..."
                            multiple={false}
                            options={RBDList}
                            //blurOnSelect
                            autoComplete
                            size='small'
                            //onBlur={getEes}
                            renderInput=
                            {
                                (params) => {
                                    return (
                                        <TextField required
                                            //onChange={(event) => handleAutoRBDchange(event, 1)}
                                            error={error} 
                                            helperText={error}
                                            {...params} label="RBD" />
                                    )
                                }
                            }
                        />
                    </FormControl>
                    </Box>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={12} >

                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.ees_name}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Responsable del establecimiento"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers[0]}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                            <Grid size={6}>

                                <TextField

                                    label="Responsable del establecimiento 2"
                                    size="small"
                                    disabled
                                    value={formData.ees_managers[1]}
                                    sx={{ width: "100%" }}
                                />

                            </Grid>
                        </Grid>
                    </FormControl>
                    <Box ref={rutRef}>
                   <InputRut error={errorRut} errorRut={handleErrorChange} checked={formData.checked} rut={formData.ees_temporal_manager} handleSwitch={handleSwitch} handleRut={(newValue) => setFormData({ ...formData, ["ees_temporal_manager"]: newValue})} ></InputRut>
                   </Box>
                    <Typography
                        component="h1"
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Datos de Conexión
                    </Typography>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={12} >
                                <InputLabel size="small" id="tech_id">Tecnología</InputLabel>
                                <Select
                                    required
                                    value={formData.tech_id}
                                    labelId="tech_id"
                                    defaultValue={0}
                                    label="Tecnología"
                                    size="small"
                                    sx={{ width: "100%" }}
                                    onChange={event => handleDropDown(event.target.value)}
                                >
                                    {techTypes.map((tech, index) => (

                                        <MenuItem key={index + "menu"} value={tech.id_tipo_tecnologia}>{tech.nombre_enlace}</MenuItem>
                                    )
                                    )}

                                </Select>
                            </Grid>
                            <Grid size={6} >
                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. subida total Nacional (Mbps)"
                                value={formData.speed_up}
                                onValueChange={(newValue) => setFormData({ ...formData, ["speed_up"]: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />


                            </Grid>
                            <Grid size={6}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={'es'}
                                    localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DateTimePicker
                                        label="Fecha instalación"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={formData.installation_date}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        onChange={(newValue) => setFormData({ ...formData, ["install_date"]: newValue })}
                                        disableFuture
                                    />
                                </LocalizationProvider>

                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                            <Grid size={6} >

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. bajada total Nacional (Mbps)"
                                value={formData.speed_down}
                                onValueChange={(newValue) => setFormData({ ...formData, ["speed_down"]: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />
                            </Grid>
                            <Grid size={6}>

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Vel. bajada total Internacional (Mbps)"
                                value={formData.int_speed_down}
                                onValueChange={(newValue) => setFormData({ ...formData, ["int_speed_down"]: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />
                               

                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: "100%", spacing: 2 }}>
                        <Grid container spacing={2}>
                        <Grid size={6} >

                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Latencia (ms)"
                                value={formData.latency}
                                onValueChange={(newValue) => setFormData({ ...formData, ["latency"]: newValue.value })}
                                allowNegative={false}
                                size="small"
                                sx={{ width: "100%" }}
                            />

                            </Grid>
                            <Grid size={6}>
                            <NumericFormat
                                required
                                customInput={TextField}
                                label="Pérdida de paquetes (%)"
                                value={formData.packet_loss}
                                onValueChange={(newValue) => setFormData({ ...formData, ["packet_loss"]: newValue.value })}
                                size="small"
                                sx={{ width: "100%" }}

                                  isAllowed={(values) => {
                                    if (values.value == "") {
                                        return true;
                                    }
                                    const { floatValue } = values;
                                    return floatValue < 100;
                                  }}
                            />

                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: "100%" }}>
                        <Paper elevation={1} sx={{ width: "100%", borderRadius: "0.5rem" }}>
                            <Grid container spacing={2}>

                                <Grid display="flex" justifyContent="flex-start" size={12} sx={{ maxHeight: "small" }}>

                                    <Typography variant="body" sx={{ padding: "0.5rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid display="flex" justifyContent="flex-start" size={12} sx={{ maxHeight: "small" }}>
                                                <CollectionsOutlinedIcon sx={{ marginRight: "0.5rem" }} /> {"Subir imágenes de evidencia."}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Divider orientation="vertical" flexItem />
                                    <LoadingButton
                                        component="label"
                                        role={undefined}
                                        variant="text"
                                        tabIndex={-1}
                                        startIcon={<FileUploadOutlinedIcon />}
                                        sx={{ textDecoration: 'underline', marginLeft: "1%" }}
                                    >
                                        Subir imágenes
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                setFormData({ ...formData, ["images"]: e.target.files })
                                            }}
                                            multiple
                                        />
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                            <Divider orientation="horizontal" flexItem />
                            <Grid container spacing={2}>
                                <Grid display="flex" justifyContent="flex-start" alignItems="center" size={12}>

                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

                                        <ListItem sx={{ justifyContent: "flex-start" }}>
                                            <Typography
                                                align="center"
                                            >
                                                Lista de imágenes a subir
                                            </Typography>
                                        </ListItem>
                                        {Array.from(formData.images).map(image => {
                                            return (
                                                <ListItem divider key={image.name} display="flex" alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar variant="rounded" alt="preview" src={URL.createObjectURL(image)} />
                                                    </ListItemAvatar>
                                                    <ListItemText primaryTypographyProps={{ style: { color: "black" } }} primary={image.name} />
                                                </ListItem>
                                            )
                                        }
                                        )
                                        }
                                    </List>

                                </Grid>
                            </Grid>

                        </Paper>

                    </FormControl>
                
                    </form>
                </Stack>
                </Box>
                <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "2rem" }}>
                    <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                        <Grid container spacing={2}>
                            {submitConfirm ?
                                <Grid display="flex" justifyContent="center" alignItems="center" size={12} >
                                    <Alert icon={false} variant="filled" severity="warning" display="flex" justifyContent="center" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                                        <Typography
                                            variant="body"
                                            color="inherit"
                                            sx={{ textAlign: "center" }}
                                        >
                                            Revisa que todos los campos tienen la información correcta antes de enviar el levantamiento de formulario FUS, al clickear “Firmar y enviar” el documento queda firmado por el operador.
                                        </Typography>
                                    </Alert>
                                </Grid>
                                :
                                ""
                            }
                            <Grid display="flex" justifyContent="center" alignItems="center" size={12} >
                                <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
                                  <PageButtonBlack form="fus" loading={loading}> Firmar y enviar</PageButtonBlack>
                                  </Stack>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Stack>
                </Stack>
            </Box>

      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a inicio" onClick={() => navigate("/home")} onClose={handleClose}></ModalStandard>
            </>

        );
    } else {
        return (
            <Box></Box>
        );
    }


}
