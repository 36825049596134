import React, { useContext, useEffect }  from "react";

import {Box} from "@mui/material";
import GeneralContext from '../GeneralContext/GeneralContext';

export default function PageBody({children}, props) {
	const { userSesion , pageTitle, setPageTitle, setDrawerHidding } = useContext(GeneralContext);


	useEffect(() => {
        setDrawerHidding(false)
    }, [props.loading, setDrawerHidding]);

    return (

        <Box display="flex" justifyContent="center" width="100%"  sx={{padding: "0" }}>
				<Box display="block" justifyContent="center" width="100%"  sx={{padding: "2rem 5rem"}}>
					{children}
				</Box>
			</Box>
    );
}

