import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper, Checkbox } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Download as DownloadIcon } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ContingenciasList from "../Contingencias/ContingenciasList";
import ListEmpty from "../GeneralComponents/ListEmpty";

import Grid from "@mui/material/Grid2";

import {ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_OP_ADMIN, ROL_ADMIN} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "Selec. todo",
    width: "5%",
    minWidth: "50px",
    align: "center",
  },
  { id: "rbd", label: "RBD", width: "12%", minWidth: "100px", align: "center" },
  { id: "nombre_operador", label: "Operador", width: "12%", minWidth: "100px" },
  { id: "nombre_zona", label: "Zona", width: "12%", minWidth: "100px" },
  {
    id: "nombre_comuna",
    label: "Comuna",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "periodo_facturacion",
    label: "Periodo",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "url",
    label: "Descargar XLS",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "url_sagec",
    label: "Prefactura SAGEC",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "url_op",
    label: "Prefactura Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "diff_action",
    label: "Acepto diferencia",
    width: "12%",
    minWidth: "120px",
    align: "left",
  },
  {
    id: "diff",
    label: "Diferencia prefactura",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "estado",
    label: "Estado",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];

export default function PrefacturaList() {
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getReportesComerciales(query)
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            estado: "Aprobado",
            diff: "-",
            diff_action: "",
            id: index + 1,
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

     if (
      includeRol([ROL_OP_ADMIN ])
    ) {
      const columnOrder = [
      "nombre_operador",
      "nombre_zona",
      "url_sagec",
      "url_op",
      "diff",
      "diff_action",
      "estado",
      "checkbox",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
    } else if (includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN])) {
       const columnOrder = [
      "periodo_facturacion",
      "nombre_operador",
      "nombre_zona",
      "url_sagec",
      "url_op",
      "diff",
      "estado",
      "checkbox",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
  };
}


  useEffect(() => {
    fetchData("");
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rejectDocs = () => {
    alert(selected)
  };
  const aproveDocs = () => {
    alert(selected)
  };
  const downloadDocs = () => {
    alert(selected)
  };

  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };

  const downloadReports = () => {
    setLoading(true);
    alert("Descargar consolidado");
    setLoading(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((n) => n.index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (rbd) => {
    const selectedIndex = selected.indexOf(rbd);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rbd);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (rbd) => selected.indexOf(rbd) !== -1;

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
     {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN]) ? (<ContingenciasList></ContingenciasList>) : null}
    <PageBody>

      <Grid
        container
        spacing={1}
        columns="10"
        sx={{ marginBottom: "28px", marginTop: "-1rem" }}
      >
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"periodo_facturacion"}
            name={"Periodo"}
            callback={filterRecords}
          />
        </Grid>
        {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN]) ? (<Grid item size={2}>
          <InputFilter
            data={rows}
            item={"nombre_operador"}
            name={"Operador"}
            callback={filterRecords}
          />
        </Grid>) : null}
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"nombre_zona"}
            name={"Zona"}
            callback={filterRecords}
          />
        </Grid>
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"diff"}
            name={"Diferencia"}
            callback={filterRecords}
          />
        </Grid>
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"estado"}
            name={"Estado"}
            callback={filterRecords}
          />
        </Grid>
      </Grid>

      {loadingTable ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <Typography
              variant="h1"
              color="inherit"
              noWrap
              sx={{ textAlign: "center", padding: "0.5rem" }}
            >
              Validación Pre-Factura
            </Typography>
          </Paper>

          <PageBgBlock pad="0">
            <Paper
              sx={{
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                background: "#FFFFFF",
                borderRadius: "0.25rem",
                marginBottom: "2rem",
              }}
            >
              <TableContainer >
                <Table size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {filteredColumns.map((column, index) => (
                        <TableCell
                          key={"CL" + index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < rowsPerPage
                                }
                                checked={
                                  tableData.length > 0 &&
                                  selected.length === rowsPerPage
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.index);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"TR" + index}
                            aria-checked={isItemSelected}
                            key={row.index}
                            selected={isItemSelected}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            {filteredColumns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={"TC" + index}
                                  align={column.align}
                                >
                                  {column.id.startsWith("url") ? (
                                    <Button
                                      onClick={() => {
                                        alert("descargar");
                                      }}
                                      key={`${column.id}.button.${index}`}
                                      variant="text"
                                      startIcon={
                                        <DownloadIcon
                                          key={`${column.id}.icon.${index}`}
                                          sx={{ color: "#111" }}
                                        />
                                      }
                                    >
                                      <Typography
                                        key={`${column.id}.typ.${index}`}
                                        sx={{
                                          color: "#111",
                                          textTransform: "capitalize",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Descargar
                                      </Typography>
                                    </Button>
                                  ) : column.id==="diff_action" ? (
                                  <Stack>
					                <Grid container spacing={1} >
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      alert("aceptar")
					                    }}
					                  >
					                      Si
					                  </PageButtonWhite>
						            </Grid>
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      navigate('/apelacion');
					                    }} 
					                  > No
					                  </PageButtonWhite>
						            </Grid>
						            </Grid>
						            </Stack>
                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.index)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.index,
                                      }}
                                    />
                                  ) : column.id === "estado" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "no-wrap",
                                      }}
                                    >
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.75rem",
                                          color:
                                            value === "En Proceso"
                                              ? "#FFE263"
                                              : value === "Aprobado"
                                              ? "#41AE43"
                                              : "#FB3B3B",
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      <Box sx={{ flexGrow: 1 }} />
                                      {value}
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                      {tableData.length == 0 ? (<ListEmpty></ListEmpty>) : null}
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </PageBgBlock>

           {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL]) ? (<Grid container spacing={2} selectedIndex={{display: "flex"}}>
          <Grid size={4}>
           
          </Grid>
          <Grid size={4}>
            <PageButtonBlack
            color="white"
              onClick={aproveDocs}
              width="100%"
            >
              Aprobar pre-factura y generar código
            </PageButtonBlack>
          </Grid>
          <Grid size={4}>
           
          </Grid>
        </Grid>) : null }

        </>
      )}

    </PageBody>
    </>
  );
}
