import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";


import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";


import { ROL_VAL_MINEDUC, ROL_REP_EES, ROL_VAL_SUBTEL} from "../../Constants/Constants";


export default function EmbeddedSubtelGrid() {
  const { includeRol, userSesion } = useContext(GeneralContext);

  return (


    <>
    <Grid container spacing={1} sx={{ padding: "0" }}>
          <Grid size={4} sx={{display: "block", minHeight: "200px"}}>
            <iframe width="100%" height="100%" allowFullScreen src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665852539&to=1731687452539&panelId=1" frameborder="0"></iframe>
          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "200px"}}>
             <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665883521&to=1731687483521&panelId=2" frameborder="0"></iframe>          

          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "200px"}}>
                <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665909916&to=1731687509916&panelId=7" frameborder="0"></iframe>      

          </Grid>
          <Grid size={12} sx={{display: "block", minHeight: "300px"}}>
                <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665925456&to=1731687525456&panelId=5" frameborder="0"></iframe>   

          </Grid>
          <Grid size={4}sx={{display: "block", minHeight: "200px"}}>
            <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665948543&to=1731687548543&panelId=6" frameborder="0"></iframe>
          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "200px"}}>
            <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665965014&to=1731687565014&panelId=9" frameborder="0"></iframe> 

          </Grid>
          <Grid size={4} sx={{display: "block", minHeight: "200px"}}>
                  <iframe width="100%" height="100%"  src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731665985602&to=1731687585602&panelId=10" frameborder="0"></iframe>    

          </Grid>
          <Grid size={12} sx={{display: "block", minHeight: "300px"}}>
                    <iframe width="100%" height="100%" src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d-solo/ce2z749519zb4b/home-subtel?orgId=1&from=1731666014969&to=1731687614969&panelId=4" frameborder="0"></iframe>  

          </Grid>
        </Grid>
  
    </>
  );
}

