import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";


import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";


import { ROL_VAL_MINEDUC, ROL_REP_EES, ROL_VAL_SUBTEL} from "../../Constants/Constants";


export default function EmbeddedMineducGrid() {
  const { includeRol, userSesion } = useContext(GeneralContext);

  return (


    <>
      <Grid container spacing={1} sx={{ padding: "0" }}>
          <Grid size={6} sx={{height: "40hv"}}>
            <iframe
src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d/be0kla10ndtz4b/reporte-acuerdos-privados?orgId=1&from=1730998723675&to=1731020323675"
      width="100%"
      height="auto"
      frameBorder="0"
      allowFullScreen
    ></iframe>

          </Grid>
          <Grid size={6}>
                          <iframe
src="https://sandbox.sagec.subtel.gob.cl/service/grafana/d/be0kla10ndtz4b/reporte-acuerdos-privados?orgId=1&from=1730998723675&to=1731020323675"
      width="100%"
      height=""
      frameBorder="0"
      allowFullScreen
    ></iframe>

          </Grid>
        </Grid>
  
    </>
  );
}

