import React, { useEffect, useContext } from "react";
import GeneralContext from '../GeneralContext/GeneralContext';
import { useNavigate } from "react-router-dom";


import { Box, Typography } from "@mui/material";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";

export default function NotFound() {

  const { setDrawerHidding } = useContext(GeneralContext);
  const navigate = useNavigate();

  useEffect(() => {
    setDrawerHidding(true)
}, [setDrawerHidding]);

  return (

<Box display="flex" justifyContent="center" alignItems="center" width="100%" minHeight="100vh" sx={{ backgroundColor: '#F6F6F6', padding: '0 3rem' }}>
  <PageBody width="50%" sx={{ minHeight: '100vh' }}>
    <Box sx={{ width: '80%', backgroundColor: '#fff', margin: 'auto', padding: '1rem', borderRadius: '.5rem' }}>
      <Grid container spacing={2} sx={{ padding: "2rem", height: '100%' }}>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
          <img 
            src="/assets/404.png" // Reemplaza con la ruta de tu imagen
            alt="imagen de error 404"
            style={{ maxHeight: '40vh', minHeight: '300px', borderRadius: '8px', padding: '0 1.5rem'}} // Ajusta el estilo a tus necesidades
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
          <Stack justifyContent="center"  spacing={2} sx={{paddingLeft: '2rem'}}>
            <Typography color="primary" variant="h1" sx={{ marginBottom: '1.5rem' }}>Error 404</Typography>
            <Typography color="primary" variant="h4" sx={{ marginBottom: '1.5rem' }}>Lo sentimos, parece que esta página no existe.</Typography>
            <Typography variant="body" >
              Si necesitas asistencia, vuelve al inicio o contáctanos.<br />
              ¡Estamos trabajando para que tu experiencia sea lo más<br />
              fluida posible!
            </Typography>
            <Box width="250px" sx={{ marginTop: '2rem' }}>
              <PageButtonBlack width="100%" onClick={() => navigate("/home")}>Volver al inicio</PageButtonBlack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  </PageBody>
</Box>


  );
}
