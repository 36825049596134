import React, { useState, useContext, useEffect }  from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import Container from "@mui/material/Container";

import axios from '../../api/axios';
import GeneralContext from '../GeneralContext/GeneralContext';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function Test() {

    const { logout, getToken, isAuthenticated, pageTitle, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
    const { authLocal } = useAxiosPrivate();
    const [rsp, setRsp] = useState({});


    const handleSubmit = async () => {
    axios.getUser()               // uncomment function call
        .then((response)=>{
          console.log(response);
          setRsp(response.data);
        })
        .catch((error) => {
          setRsp(error);
        })
  };

const clearJWT = async () => {
    setDrawerHidding(true);
    logout({});
  };

  const GoToGrafana = () => {
    // check if user is logged in?
      window.location.href = "https://sandbox.sagec.subtel.gob.cl/service/grafana";
  };

    useEffect(() => {
        setPageTitle("Formulario Único de Solicitud (FUS)")
        setDrawerHidding(false)
    }, [pageTitle, setPageTitle, setDrawerHidding]);


    return (
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
        <Box sx={{ width: '225px', margin:'2vh' }}>
            <Stack alignItems="center" justifyContent="center" spacing={2}>
        <Button variant="outlined" color="primary" onClick={handleSubmit}>
            /auth/user
          </Button>
                
          <Box sx={{ width: '900px', margin:'2vh', wordWrap: 'break-word' , justifyContent: 'center'}}>
          <p>rsp: {JSON.stringify(rsp)}</p>

        </Box>
          <Button variant="outlined" color="primary" onClick={clearJWT}>
            Borrar JWT
          </Button>

          <Button variant="outlined" color="primary" onClick={GoToGrafana}>
            Grafana
          </Button>

            </Stack>
        </Box>
        </Container>
    );
}
