import React, { useState, useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import GeneralContext from "../GeneralContext/GeneralContext";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";

const Support = () => {
  const { pageTitle, setPageTitle } = useContext(GeneralContext);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(
        {
            "title": "",
            "msg": "",
        }
    )

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSended(true);
    alert("implementar");
  };

  useEffect(() => {
    setPageTitle("Contacto de Soporte");
  }, [pageTitle, setPageTitle]);

  return (
    <PageBody>
      <PageBgBlock pad="3rem 6rem">
        <Box>
          <Typography variant="h1" align="left" sx={{ marginBottom: "1.2rem" }}>
            AYUDA Y SOPORTE
          </Typography>

          <Box
            justifyContent="left"
            sx={{ margin: "0 auto", display: "flex" }}
          >
            <Typography
              variant="body"
              align="left"
              sx={{ marginBottom: "1rem" }}
            >
              Mediante este formulario, puede detallarnos su situación. Desde el área de soporte, recibiremos su caso y le enviaremos un ID de ticket para su registro. En caso de que no lo contactemos por correo o teléfono, puede enviar un mensaje con su ID de ticket a este correo.
            </Typography>
          </Box>
          <Box
            sx={{ width: '100%', margin: "0 auto", display: "flex" }}
          >
            <Typography
              variant="body"
              align="left"
              sx={{ marginBottom: "2rem" }}
            >
              <b>Administrador SAGEC: soporte.sagec@blueshadows.cl</b>
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
          <FormControl
              sx={{ width: "100%", maxWidth: "100%", marginBottom: "39px" }}
            >
              <TextField
                required
                disabled={sended}
                id="outlined-required"
                label="Asunto"
                size="small"
                value={formData.title}
                onChange={(newValue) => setFormData({ ...formData, title: newValue.target.value })}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%", maxWidth: "100%", marginBottom: "39px" }}
            >
              <TextField
                disabled={sended}
                required
                multiline
                rows={4}
                id="outlined-required"
                label="Descripción"
                placeholder="Escriba aquí su situación"
                size="small"
                value={formData.msg}
                onChange={(newValue) => setFormData({ ...formData, msg: newValue.target.value })}
              />
            </FormControl>
            {!sended ? (
            <PageButtonBlack loading={loading}  width="312px">
              Enviar
            </PageButtonBlack>)
            :
            (<><PageButtonBlack onClick={handleSubmit} 
                loading={sended} width="312px">
              Enviar
            </PageButtonBlack>
            <Box sx={{padding: "2rem", marginTop: "2rem", borderRadius: "0.5rem", backgroundColor: "#E4E4E4", textAlign: "center"}}>
              <Typography
              variant="body"
            >
              Su ID de ticket de soporte es:  <b>XXXX-XXXX-XXXX</b>
            </Typography>

            </Box></>)

            }
            

          </form>
        </Box>
      </PageBgBlock>
    </PageBody>
  );
};

export default Support;
