import React, { useState, useEffect }  from "react";
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TuneIcon from '@mui/icons-material/Tune';
import TextField from '@mui/material/TextField';


const textFieldStyles = {
width: '100%',
/*   color: '#111',
  '& .MuiOutlinedInput-root': {
    padding: '0',
    '& fieldset': {
      border: '2px solid #ABABB5'
    },
    '&:hover fieldset': {
      borderColor: '#111',
      color: '#111',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#111',
    },
    '& ::placeholder': {
      color: '#111',
      opacity: 1, 
    },
  },
    '.Mui-focused': {
      color: '#111!important',
    },*/
};

export default function InputFilter(props) {
    const [itemName] = useState(props.item);
    const [rows, setRows] = useState(props.data);
    const [options, setOptions] = useState([...new Set(rows.map(item => item[itemName]))]);
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState('');

    const icon = props.icon ? props.icon : <TuneIcon/>;

    useEffect(() => {
        var opt = [...new Set(props.data.map(item => item[itemName]))]
        if (opt.slice().sort().join(",") !== options.slice().join(",") ){
            setRows(props.data);
            setOptions(opt);     
        }
     }, [props.data]);

    return (

        <FormControl  sx={textFieldStyles} size="small">
            <Autocomplete size="small"
            value={value || null}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.callback(itemName, newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}

            renderInput={
                (params) => {
                  return (
                    <TextField
                      {...params}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          startAdornment: (
                            icon
                          ),
                        },
                      }}
                      {...params} label={props.name} />
                  )
                }
              }
            />

            
        </FormControl>
    );
}

