import {Box, Alert, Typography} from "@mui/material";

export default function PageBgColor(props) {

    return (
		<Box style={{  }}>
              <Alert variant="outlined" severity="error" sx={{width: "100%", padding: "1rem 2rem"}}>
                <Box
                  justifyContent="center"
                  sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
                >
                  <Typography variant="body" align="left" >
                    No hay elementos para mostrar.
                  </Typography>
                </Box>
              </Alert>
            </Box>
    );
}
