
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { esES } from '@mui/material/locale';

const drawerWidth = '15rem';
const appBarHeigth = '4.5rem';

let theme = createTheme({
    palette: {
        primary: {
            main: '#0F69C4',
            light: '#BFEEFF',
            dark: '#00135A',
        },
        secondary: {
            main: '#CFE1F3',
            light: '#EBFEFF',
            dark: '#7E8FA0',
        },
        error: {
            main: '#FB3B3B',
            light: '#FFEBEF',
            dark: '#BE0A1F',
        },
        warning: {
            main: '#FF5722',
            light: '#FBE9E7',
            dark: '#BF360C',
        },
        info: {
            main: '#2196F3',
            light: '#E3F2FD',
            dark: '#0D47A1',
        },
        success: {
            main: '#4CAF50',
            light: '#E8F5E9',
            dark: '#1B5E20',
        },

    },
});

theme = createTheme(theme, {
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
    components: {

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: 'white',
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'white',
                    borderBottomLeftRadius: '1rem',
                    heigth: appBarHeigth,
                    maxHeigth: appBarHeigth,
                },
                paper: {
                    heigth: appBarHeigth,
                    maxHeigth: appBarHeigth,
                },

            }
        },
        MuiToolBar:{
            styleOverrides: {
                root:{
                    paddingLeft: 0,
                    paddingRight: 0,
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    flexShrink: 0,
                    boxSizing: 'border-box',
                    width: drawerWidth,
                },
                paper: {
                    width: drawerWidth,
                    background: theme.palette.primary.main,
                    color: 'white',
                },
                listItem: {
                    background: theme.palette.secondary.main,
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: 'white'
                },

            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'white'
                },

            }
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderWidth: '2px',
                    borderRadius: '0',
                },
                'blue-button-filled': {
                    border: '2px solid #003B8D',
                    textTransform: 'none',
                    width: '100%',
                    backgroundColor: '#003B8D',
                    borderRadius: '4px',
                    color: '#FFF',
                    padding: "8px 16px",
                    fontWeight: '500',
                    margin: 'auto'
                }
            },
        },
    },
},
    esES,
);
theme.typography.fontSize = '1rem'

theme.typography.h1 = {
    fontFamily: 'Roboto Slab',
    fontSize: '2.4rem',
    color: '#111'
}
theme.typography.h2 = {
    fontFamily: 'Roboto Slab',
    fontSize: '1.6875rem',
    color: '#111'
}
theme.typography.h3 = {
    fontFamily: 'Roboto Slab',
    fontSize: '1.4375rem',
    color: '#111'
}
theme.typography.h4 = {
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
    color: '#111'
}
theme.typography.h5 = {
    fontFamily: 'Roboto Slab',
    fontSize: '1.25rem',
    color: '#111'
}
theme.typography.h6 = {
    fontFamily: 'Roboto Slab',
    fontSize: '1.125rem',
    color: '#111'
}
theme.typography.body = {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#111',
}
theme.typography.p = {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#4a4a4a',
}
theme.typography.a = {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#111',
}
theme.typography.small = {
    fontFamily: 'Roboto',
    fontSize: '.6875rem',
    fontWeight: 400,
    color: '#111',
}
//theme.components.MuiDrawer.styleOverrides.

const GobStyle = ({ children }) => {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

export default GobStyle





