import { Box, Button } from "@mui/material";


const buttonStyles = {
    margin: 'auto',
    font: 'Roboto',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'FFFFFF',
    padding: '14px 8px',
    backgroundColor: '#0F69C4',
    textTransform: 'None',
    padding: '10px 20px',
    borderRadius: '0',
    '&:hover': {
        opacity: 'rgba(0,0,0,0.26)',
        backgroundColor: '#0B4E91'
    },
    '&:focus': {
        opacity: 'rgba(0,0,0,0.26)',
        outline: '4px solid rgba(255, 190, 92, 1)',
        backgroundColor: '#0B4E91'
    },
    '&:active': {
        opacity: 'rgba(0,0,0,0.54)',
        backgroundColor: '#07305A'
    },
    '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
};


export default function ClaveUnicaButton(props) {

    return (
        <Box sx={{display: 'flex'}}>
       
        <Button variant="contained" color="primary" onClick={props.onClick} startIcon={props.startIcon} sx={buttonStyles}>
            Iniciar sesión
        </Button> 
     
        </Box>

       

    );
}



