import React, { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Modal from '@mui/material//Modal';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from "@mui/icons-material/Close";
import GeneralContext from '../GeneralContext/GeneralContext';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Grid from '@mui/material/Grid2';
import { Divider } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import CastConnectedOutlinedIcon from '@mui/icons-material/CastConnectedOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import SAGECLogo from '../Svg/sagec-logo.svg';
import packageJson from '../../../package.json';
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";



function CustomItemList(props) {
  const { userResources } = useContext(GeneralContext);
  const location = useLocation();
  const [openFas, setOpenFas] = React.useState(false);
  const [item, setItem] = React.useState(props.item);
  const [menuList, setMenuList] = React.useState(props.menuList);
  let catName = menuList.filter((menuItem) => menuItem.res === item.nombre_recurso)[0].resource.items.categoryName
  let catNameSec = menuList.filter((menuItem) => menuItem.res === item.nombre_recurso)[0].resource.items.nameSec
  let resItems = menuList.filter((menuItem) => menuItem.res === item.nombre_recurso)[0].resource.items.items
  let icon = menuList.filter((menuItem) => menuItem.res === item.nombre_recurso)[0].resource.items.icon


    useEffect(() => {
    if (resItems.some((subItem) => location.pathname === subItem.to)) {
      setOpenFas(true);
    }
  }, [location.pathname, resItems]);

  return (
    <div>

      <ListItemButton
        key={catName + ".0"}
        sx={[
          {
            minHeight: 48,
            px: 2.5,
            justifyContent: 'initial',
          },
        ]}
        onClick={() => setOpenFas(!openFas)}
      >
        <ListItemIcon
          key={catName + ".1"}
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
              mr: 3,
            },
          ]}

        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={catName}
          secondary={catNameSec}
          secondaryTypographyProps={{ style: { color: "white" }, variant: 'small' }}
          key={catName + ".2"}
          sx={[{
            opacity: 1,
          }]}
        />
        {openFas ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </ListItemButton>

      <Collapse key={catName + ".3"} in={openFas} timeout="auto" unmountOnExit>
        {resItems.map((subItem) => (
          userResources.includes(subItem.to) ? 
          <ListItem key={subItem.name + "0.4"} component={Link} to={subItem.to}  target={subItem.to.includes("/service/grafana") ? '_blank' : '_self'} disablePadding sx={{display: 'block', maxWidth: "100%", background: location.pathname === subItem.to ? '#0051A8' : '' }}>
            <ListItemButton
              key={subItem.name + ".7"}
              sx={[
                {
                  minHeight: 48,
                  px: 2.5,
                  justifyContent: 'revert',
                },
              ]}
            >
              <ListItemText
                key={subItem.name + "0.6"}
                primary={subItem.name}
                 primaryTypographyProps={{ fontSize: '0.875rem' }}
                sx={[{
                  opacity: 1,
                  paddingLeft: "3rem",
                }
                ]}
              />
            </ListItemButton>
          </ListItem>
          : null
        ))
        }
      </Collapse>
    </div>
  )
}


export default function DrawerBase({ children }) {

  const location = useLocation();
  const { logout, pageTitle, drawerHidding, userSesion, loading } = useContext(GeneralContext);
  const [open, setOpen] = React.useState(true);
  const [auth, setAuth] = React.useState(true);
  const version = packageJson['version'];

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const menuList = [
    { res: '/fas_lista', resource: { type: "Category", items: { categoryName: "FAS", items: [{ name: "Crear Nuevo", nameSec: "", to: "/fas", icon: <CollectionsBookmarkIcon /> }, { name: "Ver listado", nameSec: "", to: "/fas_lista", icon: <DomainAddIcon /> }], icon: <AssignmentOutlinedIcon />, sectionName: "FAS", nameSec: "Formulario Alta de Servicio" } } },
    { res: '/fus_lista', resource: { type: "Category", items: { categoryName: "FUS", items: [{ name: "Crear Nuevo", nameSec: "", to: "/fus", icon: <CollectionsBookmarkIcon /> }, { name: "Ver listado", nameSec: "", to: "/fus_lista", icon: <DomainAddIcon /> }], icon: <AssignmentOutlinedIcon />, sectionName: "FUS", nameSec: "Formulario Upgrade del Servicio" } } },
    //{ res: '/ees', resource: { type: "Item", items: [{ name: "EES", nameSec: "Vista EES", to: "/ees", icon: <SchoolIcon /> }] } },
    //{ res: '/validador', resource: { type: "Item", items: [{ name: "Validador", nameSec: "", to: "/validador", icon: <HowToRegIcon /> }] } },
    //{ res: '/m2m', resource: { type: "Item", items: [{ name: "M2M", nameSec: "", to: "/m2m", icon: <LaptopChromebookIcon /> }] } },
    { res: '/uuid', resource: { type: "Item", items: [{ name: "Estado UUID", nameSec: "", to: "/uuid", icon: <CastConnectedOutlinedIcon /> }] } },
    { res: '/configuracion', resource: { type: "Item", items: [{ name: "Configuración", nameSec: "", to: "/configuracion", icon: <SummarizeIcon /> }] } },
    { res: '/reportes', resource: { type: "Category", items: { categoryName: "Reportes", items: [{ name: "Monitoreo en línea", nameSec: "",to: "/service/grafana/d/be0kjiscwhzwga/reporte-supervision", icon: <DomainAddIcon /> }, { name: "Tendencias de navegación", nameSec: "",to: "/service/grafana/d/ee0kkdi6t90jka/reporte-de-uso", icon: <DomainAddIcon /> }, { name: "Acuerdos privados", nameSec: "",to: "/service/grafana/d/be0kla10ndtz4b/reporte-acuerdos-privados", icon: <DomainAddIcon /> }, { name: "De Comportamiento", nameSec: "",to: "/service/grafana/d/de0kjuhx3lgxsd/reporte-de-comportamiento", icon: <DomainAddIcon /> }, { name: "Muestras", nameSec: "",to: "/service/grafana/d/fe0kk8urdrfgga/reporte-de-uso3a-muestra-de-ees", icon: <DomainAddIcon /> }, { name: "Estado Parque EES", nameSec: "",to: "/service/grafana/d/fe0kk6fnoq51cf/reporte-estado-parque-ess", icon: <DomainAddIcon /> }, { name: "Reporte Operación", nameSec: "",to: "/service/grafana/d/de0kkffhmu58gb/reporte-operacion", icon: <DomainAddIcon /> }, { name: "Ticket Mesa De Ayuda", nameSec: "",to: "/service/grafana/d/ee0kkchiuemm8c/reporte-ticket-mesa-de-ayuda", icon: <DomainAddIcon /> }, { name: "Valores Máximos Diarios", nameSec: "",to: "/service/grafana/d/ee0kjs3qz71tsc/reporte-valores-maximos-diarios", icon: <DomainAddIcon /> }], icon: <DifferenceOutlinedIcon />, sectionName: "Reportes", nameSec: "" } } },
    { res: '/prefactura', resource: { type: "Category", items: { categoryName: "Pre-factura", items: [{ name: "Validaciones", nameSec: "", to: "/prefactura", icon: <CollectionsBookmarkIcon /> }, { name: "Listado de apelaciones", nameSec: "", to: "/apelaciones_lista", icon: <DomainAddIcon /> }], icon: <DifferenceOutlinedIcon />, sectionName: "Reportes", nameSec: "" } } },
    { res: '/test_auth', resource: { type: "Item", items: [{ name: "Testing Auth", nameSec: "", to: "/test_auth", icon: <SummarizeIcon /> }] } },
    { res: '/contingencias_lista', resource: { type: "Category", items: { categoryName: "Contingencias", items: [{ name: "Crear Nuevo", nameSec: "", to: "/contingencias", icon: <NotificationImportantOutlinedIcon /> }, { name: "Ver listado", nameSec: "", to: "/contingencias_lista", icon: <DomainAddIcon /> }], icon: <NotificationImportantOutlinedIcon />, sectionName: "Contingencias", nameSec: "" } } },

    //{ res: '/consulta-subtel', resource: { type: "Item", items: [{ name: "Consulta SUBTEL", nameSec: "", to: "/consulta-subtel", icon: <LanIcon /> }] } },
    //{ res: '/consulta-mineduc', resource: { type: "Item", items: [{ name: "Consulta MINEDUC", nameSec: "", to: "/consulta-mineduc", icon: <AccountBalanceIcon /> }] } },
    //{ res: '/analista', resource: { type: "Item", items: [{ name: "Analista", nameSec: "", to: "/analista", icon: <QueryStatsIcon /> }] } },
  ];



  if (drawerHidding) {
    return <Box sx={{ display: 'flex' }}>{children}</Box>;
  }

  if (loading) return null;



  const renderMenuItems = () =>
    userSesion && userSesion.roles[0].recursos.map((item) => {
      const menuItem = menuList.find((menu) => menu.res === item.nombre_recurso);
      if (!menuItem) return null;

      if (menuItem.resource.type === "Category") {
        return <CustomItemList key={item.recurso_id} item={item} menuList={menuList} />;
      }

      return (
        <ListItem key={item.recurso_id} component={Link} to={menuItem.resource.items[0].to} disablePadding
          sx={{ display: 'block', background: location.pathname === menuItem.resource.items[0].to ? '#0051A8' : '' }}>
          <ListItemButton sx={{ minHeight: 48, px: 2.5, justifyContent: open ? 'initial' : 'center' }}>
            <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center', mr: open ? 3 : 'auto' }}>
              {menuItem.resource.items[0].icon}
            </ListItemIcon>
            <ListItemText primary={menuItem.resource.items[0].name} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      );
    });

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', minWidth: '100vw', backgroundColor: '#F6F6F6' }}>


<CssBaseline />
      <AppBar position="fixed" elevation={0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar disableGutters>
          <Grid container sx={{ minWidth: '15rem' }}>
            <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
              <Box component={Link} to="/home">
                <img src={SAGECLogo} alt="SAGEC Logo" style={{ width: '7rem' }} />
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: '2rem', fontWeight: 700 }}>
            {userSesion?.roles[0]?.nombre_rol || ""}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Divider orientation="vertical" flexItem />

          <Box sx={{ minHeight: 'inherit', display: 'flex', backgroundColor: location.pathname === '/configuracion' ? '#0051A8' : 'inherit' }}>
            {auth && (
              <Box sx={{ paddingX: '1rem', display: { md: 'flex' }, alignItems: 'center' }}>
                <AccountCircleOutlinedIcon sx={{ fontSize: '2rem', color:  location.pathname === '/configuracion' ? '#fff' : '#0051A8' }} />
                <Typography variant="body" sx={{ paddingX: '0.5rem', color: location.pathname === '/configuracion' ? '#fff' : '#111' }}>
                  {userSesion?.nombre || ""}
                </Typography>
                <IconButton component={Link} to="/configuracion" size="large" color="inherit" aria-label="account of current user">
                  <CreateOutlinedIcon sx={{ fontSize: '1rem', color: location.pathname === '/configuracion' ? '#fff' : '#111' }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>



      <Drawer open={open} variant="permanent">
        <Toolbar />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <List>
            <ListItem component={Link} to="/home" disablePadding sx={{ display: 'block', background: location.pathname === '/home' ? '#0051A8' : '' }}>
              <ListItemButton sx={{ minHeight: 48, px: 2.5, justifyContent: open ? 'initial' : 'center' }}>
                <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center', mr: open ? 3 : 'auto' }}>
                  <HomeOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Inicio" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            {renderMenuItems()}
            <ListItem component={Link} to="/ayuda" disablePadding sx={{ display: 'block', background: location.pathname === '/ayuda' ? '#0051A8' : '' }}>
              <ListItemButton sx={{ minHeight: 48, px: 2.5, justifyContent: open ? 'initial' : 'center' }}>
                <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center', mr: open ? 3 : 'auto' }}>
                  <HelpOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Ayuda" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton onClick={handleOpenModal} sx={{ minHeight: 48, px: 2.5, justifyContent: open ? 'initial' : 'center' }}>
                <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center', mr: open ? 3 : 'auto' }}>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
          <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="small" color="white">
                        SAGEC v{version}
                    </Typography>
                </Box>
        </Box>
      </Drawer>

      <Box sx={{ flexGrow: 1, p: 3, width: '100%' }}>
        <Toolbar />
        {children}
      </Box>

          <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        slotProps={{ backdrop: { sx: { opacity: "0.1!important" } } }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "749px",
            height: "261px",
            backgroundSize: "background.paper",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>

      
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
              sx={{
                fontSize: "26px",
                lineHeight: "66px",
                margin: "0",
                color: "#333",
              }}
            >
              ¿Seguro que desea salir?
            </Typography>

            <Box justifyContent="center" sx={{ maxWidth: "400px", margin: "0 auto", display: "flex" }} >
              <Typography variant="body" align="center" ></Typography>
            </Box>
            <Grid container spacing={2} sx={{ paddingTop: "2rem" }}>
            <Grid size={4}></Grid>
                <Grid size={2}>

            <PageButtonWhite width="6rem" onClick={handleCloseModal}>
              Volver
            </PageButtonWhite>

          </Grid>
                <Grid size={2}>
            <PageButtonBlack width="6rem" onClick={() => {logout()}}>
              Salir
            </PageButtonBlack>
          </Grid>
          </Grid>
          </Paper>
        </Box>
      </Modal>

    </Box>
  );
}

