import { axiosPrivate } from "../api/axios";
import { useEffect, useContext } from "react";
//import useRefreshToken from "./useRefreshToken";
import GeneralContext from '../Components/GeneralContext/GeneralContext';
import SnackbarContext from "../Components/GeneralContext/SnackbarContext"; 
import { useNavigate } from 'react-router-dom';
const useAxiosPrivate = () => {
    //const refresh = useRefreshToken();
    const {getToken} = useContext(GeneralContext);
    const openSnackbar = useContext(SnackbarContext);
    const navigate = useNavigate();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${getToken()}`;
                }
                
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => {
                if (response.config.showSnackbarOnSuccess) { 
                    console.log(response.config.showSnackbarOnSuccess)
                    openSnackbar(response.config.showSnackbarOnSuccess, "success");
                }
                return response;
            },
            async (error) => {

                const prevRequest = error?.config;
                if (prevRequest.showSnackbarOnError) {
                    console.log(error?.response?.data?.detail);
                    openSnackbar("Error: "+error?.response?.data?.detail, "error");
                  }
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    //const newAccessToken = await refresh();
                    //prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }else if(error?.response?.status === 401){
                    //setAuthLocal({});
                    //navigate("/");
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [navigate])

    return axiosPrivate;
}

export default useAxiosPrivate;