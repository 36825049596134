import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import axios from "../../api/axios";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputRut from "../GeneralComponents/InputRut";
import {  Button, InputAdornment, } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileUploadIcon from '@mui/icons-material/FileUpload';



import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_VAL_MINEDUC} from "../../Constants/Constants";

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ApelacionValidacion() {
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =  useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);


  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { id } = useParams();
  const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado apelaciones', path: '/apelaciones_lista' },
    { name: 'Detalle apelación', path: '/apelacion_detalle/'+id },
  ];


   const [formData, setFormData] = useState({
    operator_id: "",
    operator_name: "",
    zona: "",
    periodo: "",
    description: "",
    evidence: "",
  });

    const fetchData = async (query) => {
    setLoading(true);
    axios
      .getContingencia(id)
      .then((response) => {
        setFormData(response.data.datos)
      })
      .catch((error) => {setDocumentVisible(false)})
      .finally(() => {
        setLoading(false);
      });
  };



  useEffect(() => {
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);


  if (pageLoaded) {
    return (
        <>
          <PageBody>
          <BreadcrumbStandard links={links} />
        {loading ? (
        <PageBgBlock pad="2rem 9rem">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
        </PageBgBlock>

        ) : (
      documentVisible || true ? 


      <>
      <PageBgBlock pad="0" >
      <Box sx={{ padding: "2rem 9rem"}}> 
        
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN PRE-FACTURA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su factura proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, como documentos, correos electrónicos o comprobantes, para que su caso pueda ser evaluado de manera justa y objetiva.
          </Typography>
        </Box>

        <form id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_id"
                    label="ID Operador"
                    size="small"
                    value={formData.operator_id}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>



            <Grid container spacing={2}>

              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Periodo"
                        size="small"
                        value={formData.periodo}
                        sx={{ width: "100%" }}
                    />
                </FormControl>

                            </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="zona-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="zona-label"
                    id="zona"
                    value={formData.zona}
                    label="Zona"
                    size="small"
                    sx={{ width: "100%" }}
                  >


                    <MenuItem value={"Critica"}>1</MenuItem>
                    <MenuItem value={"Mayor"}>2</MenuItem>
                    <MenuItem value={"Menor"}>...</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

                            

            </Grid>

          
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                disabled
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el EES"
                size="small"
                value={formData.description}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    description: event.target.value,
                  })
                }
              />
            </FormControl>
            <Grid container spacing={2}>
            <Grid size={9}>
              <TextField
                disabled
                size="small"
                variant="outlined"
                placeholder="Subir evidencia (formato PDF)"
                value={formData.evidence}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{marginLeft: "10px"}}>
                      <ImageSearchIcon sx={{color: 'black'}}/>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                sx={{ width: "100%", maxWidth: "100%" }}
                readOnly
              />
            </Grid>
            <Grid size={3}>
              <Button fullWidth variant="text" color="primary" disabled startIcon={<FileUploadIcon />} >
                Añadir archivo
              </Button>
            </Grid>
          </Grid>
          <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              JUSTIFICACIÓN DE SUBTEL
            </Typography>
          <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Escriba aquí la justificación"
                size="small"
                value={formData.subtel_response}
                disabled
              />
            </FormControl>
          </Stack>

        </form>
      
          
          </Box>

      </PageBgBlock>
      </>
       :

        <PageBgBlock pad="4rem 9rem">

            <Box align="center">
              <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
            </Box>
            <Typography
            align="center"
              variant="h1"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
            >
              No disponible
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                El documento al que intenta acceder no esta diponible para ser firmado.
              </Typography>
            </Box>
            </PageBgBlock>
      
    )}
   
    </PageBody>
              </>
    );
  }
}
