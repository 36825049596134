
import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import useAxiosPrivate from "./useAxiosPrivate";

import PageLoading from '../Components/GeneralComponents/PageLoading';
import GeneralContext from '../Components/GeneralContext/GeneralContext';

const RequireAuth = () => {
    const { userResources, loading, isAuthenticated } = useContext(GeneralContext);
    const location = useLocation();
    useAxiosPrivate();

    return (
        !isAuthenticated() ? <Navigate to="/login" reloadDocument /> : loading ? <PageLoading/> :  <Outlet />
    );
}

export default RequireAuth;