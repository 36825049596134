import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbStandard = ({ links }) => {
  return (
    <Box sx={{paddingBottom: "1.5rem", display: "block"}}>
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((link, index) => (
        index < links.length - 1 ? (
          <Link 
            key={index} 
            component={RouterLink} 
            to={link.path} 
            color="inherit"
          >
            {link.name}
          </Link>
        ) : (
          <Typography key={index} color="text.primary">
            {link.name}
          </Typography>
        )
      ))}
    </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbStandard;
