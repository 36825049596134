import { CircularProgress, Box, Typography } from '@mui/material';

export default function PageLoading() {

    return (
		<Box
	      sx={{
	        display: 'flex',
	        flexDirection: 'column',
	        justifyContent: 'center',
	        alignItems: 'center',
	        height: '100vh',
	        width: '100%',
	        backgroundColor: '#f0f0f0' 
	      }}
	    >
	      <CircularProgress />
	      <Typography variant="h6" sx={{ marginTop: 2 }}>
	        Cargando...
	      </Typography>
	    </Box> 
    );
}
