import React, { useState, useContext, useEffect }  from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from '@mui/lab';
import OutboxIcon from '@mui/icons-material/Outbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GeneralContext from '../GeneralContext/GeneralContext';


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);


export default function ConsultaS() {

    const { pageTitle, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
    
    const [loading, setLoading] = useState(false);
/*    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(
        {
            "ees_id": "",
            "institution_name": "",
            "institution_asignee": "",
            "phone_asignee": "",
            "phone_email": "",
            "conn_type": "",
            "speed": "",
            "speed_international": "",
            "installation_start_date": "",
            "installation_finish_date": "",
            "installation_state": "",
            "ip": "",
            "installed_equipment": "",
            "config": "",
            "institution_asignee_esign": "",
            "technician_esign": "",
            "validation_date": "",
        }
    )

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
*/
    function getFile() {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "id": 0, "name": "Prueba", "value": "Test" })
        };
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        fetch('http://apicrud.sagec.dev.iot.bluemonster.cl/fas/', requestOptions)
            .then(response => console.log(response))
    }
    
    function handleClick() {
        setLoading(true);
    
        try {
    
            getFile()
            setLoading(false);
        } catch (error) {
            console.log("Error ", error)
            setLoading(false);
        }
    
    }

    function handleDropDown(value) {

        console.log(value)
        
    }

    useEffect(() => {
        setPageTitle("Interfaz del Validador SUBTEL - SAGEC")
        setDrawerHidding(false)
    }, [pageTitle, setPageTitle, setDrawerHidding]);


    return (

        <Box>
            <Stack alignItems="center" justifyContent="center" spacing={2}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Identificación del EES
                </Typography>

                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Código único de identificación (RBD)"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Nombre del establecimiento"
                        defaultValue=""
                    />
                </FormControl>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Datos de Contacto
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Nombre del responsable del establecimiento"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Teléfono de contacto"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Correo electrónico del responsable"
                        defaultValue=""
                    />
                </FormControl>

                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Detalles de la Conectividad
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>

                    <InputLabel id="demo-simple-select-label">Tipo de conexión proporcionada</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={"Fibra Óptica"}
                        label="Tópico"
                        onChange={event => handleDropDown(event.target.value)}
                    >
                        <MenuItem value={"0"}>Fibra Óptica</MenuItem>
                        <MenuItem value={"1"}>ADSL</MenuItem>
                        <MenuItem value={"2"}>Módem 5G</MenuItem>
                        <MenuItem value={"3"}>Otro</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Ancho de banda total comprometido (Mbps)"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Ancho de banda internacional comprometido (Mbps)"
                        defaultValue=""
                    />
                </FormControl>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Información de la Instalación
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Fecha de inicio de la instalación"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Fecha de finalización de la instalación"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <InputLabel id="demo-simple-select-label">Estado de la instalación</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={"Pendiente"}
                        label="Tópico"
                        onChange={event => handleDropDown(event.target.value)}
                    >
                        <MenuItem value={"0"}>Pendiente</MenuItem>
                        <MenuItem value={"1"}>Completado</MenuItem>
                        <MenuItem value={"2"}>Validación</MenuItem>
                        <MenuItem value={"3"}>Otro</MenuItem>
                    </Select>
                </FormControl>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Datos Técnicos
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="IP asignada"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Equipos instalados (routers, switches, etc.)"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Configuraciones específicas requeridas"
                        defaultValue=""
                    />
                </FormControl>

                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Validación y Aprobación
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Tipo de conexión proporcionada"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Ancho de banda total comprometido (Mbps)"
                        defaultValue=""
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Correo electrónico del responsable"
                        defaultValue=""
                    />
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: "75%" }}>
                    <LoadingButton
                        color="secondary"
                        onClick={handleClick}//()}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<OutboxIcon />}
                        variant="contained"
                    >
                        <span>Firmar y Enviar</span>
                    </LoadingButton>
                </FormControl>
            </Stack>
        </Box>
    );
}
