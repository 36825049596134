import { Box, Button } from "@mui/material";

export default function PageButtonBlue(props) {

    const fs = props.fs ? props.fs : "18px";
    const pad = props.pad ? props.pad : "4px 16px";
    const width = props.width ? props.width : '300px';
    const icon = props.icon ? props.icon : '';

    return (
        <Box sx={{width: width, display: 'block', margin:'auto'}}>
        {props.onClick ? 
        <Button color="primary" variant="outlined" onClick={props.onClick} endIcon={icon} disabled={props.loading} >
            {props.loading ? 'Cargando...' : props.children}
        </Button> 
        :
        <Button color="primary" variant="outlined" type="submit" endIcon={icon} disabled={props.loading} >
            {props.loading ? 'Cargando...' : props.children}
        </Button>}
        </Box>

    );
}

